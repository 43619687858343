.header{
    background: $header-color-fondo;
    height: 60px;
    width: 100%;
	z-index: 10;
    border-bottom: 2px solid #ddd;
    // background-color: gray;
    @media screen and (min-width:47em){
		border-bottom:	none;
        position: relative;
        height: 90px;
    }
    &__logo{
        width: 150px;
        height: auto;
        float: left;
        margin: 0;
        padding: 20px 0px 0px 5px;
        &--txt{
            color: $header-color-logo;
            font-size: 1em;
            font-weight: bold;
        }
        @media screen and (min-width:47em){
            width: auto;
            padding-top: 33px;
            font-size: 1.4em;
        }
    }
    
    // &__boton{
    //     float: right;
    //     margin: 0;
    //     padding-right: 10px;
    //     padding-top: 10px;
    //     color: $paleta-celeste;
	// 	cursor: pointer;
    //     @media screen and (min-width:47em){
	// 		 display: none;
    //     }
    // }

    label{
        float: right;
        margin: 0;
        padding-right: 10px;
        padding-top: 10px;
        color: $paleta-celeste;
		cursor: pointer;
        @media screen and (min-width:47em){
			 display: none;
        }
    }

    &__checkbox{
        display: none;
    }


   
    &__nav{
        background:white;
        width: 100%;
        margin: 0px auto;
        margin-top: 1px;
        // display: none;
        z-index: 100;
		position: absolute;
		left: 0;
		top: 59px;
        // transform: translateX(0%);
        // transition: all 1s;
        // left: -20%;

         position: absolute;
            // width: 70%;
            margin-left: -100%;
            transition: all 0.5s;
        @media screen and (min-width:47em){
            position: inherit;
            display: block;
            width: auto;
            height: 25px;
            float: right;
            margin-top: 25px;
        }
    }
    .menu{
        /*
            margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    @media (max-width:768px) {
      flex-direction: column;
    }
    &__item{
       border-right: 1px solid #fff;
      &:hover{
        background: rgba(0,0,0,0.3);
      }
      @media (max-width:768px) {
        border-top: 1px solid #fff;
      }
    }
    
    &__link{
      display: block;
      padding: 15px 20px;
      color: #fff;
      text-decoration: none;
    }
        */
        background-color: #fff;
        border-bottom: 2px solid #ddd;
        margin:0;
        list-style: none;
        z-index: 100;
        @media screen and (min-width:47em){
            width: 100%;
			border-bottom: none;
			display: flex;
			justify-content: center;
			flex-wrap: nowrap;
            //@include flex(center,nowrap)
        }
        &__item{
            text-align: center;
            padding: 0px;          
            @media screen and (min-width:47em){
            }
        }
        &__link{  
            text-decoration: none;
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: block;
            padding: 12px;//15px;
            color:$paleta-celeste;
			font-size: 0.9em;
            text-align: center;
            // transition: all 0.1s;
            &:hover{
                background-color: $menu-item-active;
                color: $menu-color-texto-active;
                @media screen and (min-width:47em){
                    height: 70%;
                    margin-top: 5px;
                    padding-top: 7px;
                    border-radius: 8px;
                }
            }
            
            // &--active{
            //     background-color: $menu-item-active;
            //     color: $menu-color-texto-active;
            //     border-radius: 8px;
            //     height: 70%;
            //     margin-top: 5px;
            //     padding-top: 7px;
            // }
		}
		
    }
    .icon-bars:before{
		 font-size: 2.2em;   
	} 
 

}

#icon_bar{
  display: none;
}

#icon_bar:checked ~ .header__nav {
  margin: 0;
}

    

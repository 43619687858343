.banner{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: static;
    padding-bottom: 20px;
	height: 400px;
    clear: both;
    z-index: 2;
    overflow: hidden;
    @media screen and (min-width:47em){
        margin-top: 0;
        padding-top: 0;
        }
    @media screen and (min-width:60em){
        padding-bottom: 50px;
        height: 530px;
    }
    &__frente{
        background-color: $color-primario;
        opacity: .9; // TODO: esta opacity no se esta reflejando cuando subimos el proyecto al servidor
        position: absolute;
        width: 100%;
        height: 400px;
        z-index:0;
        margin: 0 auto;
        @media screen and (min-width:60em){
            height: 530px;
        }
    }
    &__container{
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media screen and (min-width:47em){
        }
        @media screen and (min-width:60em){
            height: 530px;
        }
    }
    
    &__descripcion{
        z-index: 1;
        &--titulo,&--subtitulo{
            max-width: 400px;
            font-size: 1.4em;
            margin: 0;
            margin-bottom: 15px;
            @media screen and (min-width:47em){
                text-align: left;
            }
             @media screen and (min-width:60em){
                 max-width: none;
                 width: 550px;
            }
        }
        &--titulo{
            font-size: 2em;
            @media screen and (min-width:60em){
                font-size: 3em;
                
           }
        }
        &--boton{
            display: block;
            width: 85%;
            max-width: 120px;
            padding: .8em;
            background: $color-secundario;
            color: $color-primario;
            border-radius: .4em;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            @media screen and (min-width:47em){
            }
        }
   
    }
}
.footer{
    background: $paleta-oscuro;
    color:#fff;
    padding: 20px 0;
    height: auto;
    z-index: -1;
    padding-top: 40px;
    @media screen and (min-width:30em){
        padding: 70px 0 30px 0;
    }
    
    &__container{
        display: flex;
        flex-direction: column;
        @media screen and (min-width:45em){
            flex-direction: row-reverse;
            justify-content: space-around;
            align-items: center;
        }  
    }
    &__item{
        padding: 10px;
        margin-bottom: 20px;
        text-align:center;
    }
    .contacto{
        &__telefono{
            color: #fff;
            font-weight: 200;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            font-size: 0.9em;
            justify-content: center;
            @media screen and (min-width:30em){
                padding-top: 0;
                margin-top: 0;
            }
        }
    }

    .icon-telephone{
        font-size: 1.2em;
        padding-right: 3px;
        margin-top: 6px;
        @media screen and (min-width:30em){
         font-size: 1.4em;       
        }
    }

    .idioma{
        &__titulo{
            color: #fff;
            margin-bottom: 20px;
            font-weight: 100;
            margin-top: 10px;
            font-size: 1.2em;
            @media screen and (min-width:30em){
                margin-bottom: 40px;
            }
        }
        &__icono::before{
            font-size: 1.8em;
        }
        &__paises{
            width: 150px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            a{ 
                color: #fff;
                padding-left: 20px;
            }
        }
    }

    .icon-tree:before{
        font-size: 2.6em;
    }

    .firma{
        &__menu{
            list-style: none;
            &--item{
                padding: 4px;
                font-size: .9em;
                text-align:center;
                @media screen and (min-width:45em){
                    padding: 5px 0;
                    text-align:left;
                }
            }
            .icono{
                padding-right: 10px;
            }
		}
    }
}
.trabajos{
    padding-top: 50px;
    &__container{
        @media screen and (min-width:30em){
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
       }
    }
    
    &__titulo--seccion{
        margin-bottom: 50px;
    }

    &__imagen{
        position: absolute;
        top: 0;
        left: 0;
    }
    
    &__item{
        width: 85%;
        max-width: 223px;
        height: 226px;
		margin: 0 auto;
        margin-bottom: 30px;
        position: relative;
        border-radius: 5px;
        background: linear-gradient(#81B4FF, #1568E3);
        position: relative;
        overflow: hidden;
        @media(min-width:768px){
        }

        @media screen and (min-width:60em){
            margin: 15px 20px;
        }
        &--trasera{
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(rgba(129,180,255,1), rgba(21,104,227,0.9));
            height: 100%;
            width: 100%;
        }
    }
    
    .titulo{
        font-size: 1.6em;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
        color: #fff;
    }

    .subtitulo{
        display: block;
        width: 100%;
        height: 50px;
        color:#fff;
        text-align: center;
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 100;
        margin-bottom: 40px;
        &--link{
            color: #fff;
            font-style: italic;
            text-decoration: none;
        }

    }
    .vermas{
        color:#fff;
        display: block;
        width: 170px;
        max-height: 28px;
        margin: 0 auto;
        padding: 5px 0px;
        border-radius: 15px;
        border: 1px solid #fff;
        text-align: center;
        text-decoration:none;
        transition: all .5s;
        background-color:$color-primario;
        font-size: 14px;
        &:hover{
            color: $color-primario;
            background-color: #fff;
        }
    }
}
 
.contacto{
    padding-bottom: 80px;
    &__form--container{
        border: 1px solid rgba(77,176,253,.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 830px;
        margin: 0 auto;
        border-radius: 15px;
        @media screen and (min-width:60em){
            height: 460px;
            flex-direction: row;
        }
    
    }

    &__titulo--seccion{
        margin-bottom: 5px;
        font-size: 1.6em;
    }

    &__subtitulo--seccion{
        text-align: center;
        margin-bottom: 20px;
        color: #6F6969;
        font-size: .9em;
        font-weight: 100;
        @media screen and (min-width:47em){
            padding-bottom: 20px;
        }
    }

    &__email--result{
        text-align: center;
        background-color: steelblue;
        padding: 10px;
        color: $titulo-color;
        width: 75%;
        margin: 10px auto;
        background-color: $color-primario;
        border-radius: 10px;
    }

    &__imagen{
        display: block;
        width: 100%;
        object-fit: fill;
        border-radius: 0px 0px 15px 15px;
        @media screen and (min-width:47em){
            border-radius: 0px 15px 15px 0px;
        }
    }

    &__izquierda{
        flex: 1 0 56%;/*56%;*/
        padding-top: 30px;
    }

    &__derecha{
        flex: 1 0 44%;/*44%;*/
        overflow: hidden;
        background-color: $contacto-fondo-imagen;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .formulario{
        //padding: 5px;
        &--item{
            width: 75%;
            display: block;
            background: none;
            color: #444;
            padding: 10px 5px;
            margin: 0 auto;
            margin-bottom: 20px;
            resize: none;
            border: none;
            border: 1px solid rgba(77,176,253,.4);
            border-radius: 10px;
            &[type="button"]{
                color: #FFF;
                cursor: pointer;
                border: none;
                border-radius: 10px;
                background: linear-gradient(to right,#81B4FF, #1568E3);
                padding: 8px 12px;
                display: block;
                width: 150px;
                margin: 20px auto;
                margin-top: 28px;
                transition: all .5s;
                max-width: 133px;
                &:hover{
                    background: $paleta-oscuro;
                }
            }
        }
    }
    .creditos{
        text-align: center;
        font-size: .9em;
    }
    &-errores{
        width: 80%;
        font-size: 1em;
        display: none;
        /*background: rgba(231, 76, 60,1.0);
        */color: rgba(231, 76, 60,1.0);
        padding: 0 5px 0 5px;
    }
    
    &__form--resultado p{
         display: block;
         width: 100%;
         height: auto;//50px;
         color: aliceblue;
         text-align: center;
         z-index: 1000;
         margin:0px 0 10px 0;
         padding: 20px 10px;
         text-align: center;
         font-size: 1.1em;
         color: #fff;//#9C9C9C;
    }
    
    .envio-correcto{
       background: #1E824C;// background: rgba(39, 174, 96,1.0);
    }

    .envio-incorrecto{
        background: rgba(231, 76, 60,1.0);
    }
}